@use 'sass:math';
@use 'src/assets/styles/mixins.scss';

.pageNotFound {
  padding-top: 6rem;
  margin-bottom: 12rem;

  @include mixins.mediaSmall {
    padding: 6rem 2rem 0;
    margin-bottom: 8rem;
  }
}

.pageNotFoundHeading {
  line-height: 1;
  font-size: 2.4rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.pageNotFoundSymbol {
  font-size: 8rem;
  font-weight: 800;
  line-height: 1;
  letter-spacing: 0.05em;
  text-align: center;
  margin-top: 3.2rem;
  margin-bottom: 4rem;

  @include mixins.mediaSmall {
    margin-top: 2.4rem;
    margin-bottom: 3.2rem;
  }
}

.pageNotFoundText {
  line-height: 2;
  font-weight: 300;
  font-size: 1.6rem;
  text-align: center;

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }
}

.pageNotFoundLink {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4rem auto 0;
  border-radius: 100px;
  font-weight: 600;
  background-color: var(--blue);
  color: var(--white);
  width: 22rem;
  height: 4.8rem;
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  border: 2px solid var(--blue);
  transition: background-color var(--durationShort), color var(--durationShort);

  &:hover {
    background-color: var(--white);
    color: var(--blue);
  }
}

.miterlimit {
  stroke-miterlimit: 10px;
}
